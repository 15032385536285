import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../utils';
import axios from 'axios';

const initialState = {
  loading: false,
  data: null,
  users: [],
};

export const getUserProfile = createAsyncThunk('userProfile/getUserProfile', async () => {
  try {
    let token = localStorage.getItem('hr-auth-token');
    const { data } = await axios({
      method: 'GET',
      url: `${baseUrl}/employer/profile`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const listAllUsers = createAsyncThunk('userProfile/listAllUsers', async ({ id }) => {
  try {
    let token = localStorage.getItem('hr-auth-token');
    const { data } = await axios({
      method: 'GET',
      url: `${baseUrl}/user/profile/Employer?accessId=${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('daaa', data);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const addNewUser = createAsyncThunk('userProfile/addNewUser', async (data) => {
  try {
    let token = localStorage.getItem('hr-auth-token');
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}/user/profile`,
      data: data.data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.api.responseCode === 2010) {
      data.notification('success', response.data.message);
      data.addUserScreen(false);
    } else {
      data.notification('error', response.data.message);
    }
    return response.data;
  } catch (error) {
    data.notification('error', error.response.data.message);
  }
});

export const changePassword = createAsyncThunk('userProfile/changePassword', async (data) => {
  try {
    let token = localStorage.getItem('hr-auth-token');
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}/user/change-password`,
      data: data.data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    data.notification('success');
    data.navigate(false);
    console.log(data);
    return response.data;
  } catch (error) {
    data.notification('error', error.response.data.message);
  }
});

export const changeUserStatus = createAsyncThunk(
  'userProfile/changeUserStatus',
  async ({ userId, statusParam, openNotificationWithIcon }) => {
    console.log('statusParam', statusParam);
    try {
      let token = localStorage.getItem('hr-auth-token');
      const { data } = await axios({
        method: 'PATCH',
        url: `${baseUrl}/user/${userId}/${statusParam}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      openNotificationWithIcon('success', data.message);
      return { userId, statusParam };
    } catch (error) {
      openNotificationWithIcon('error');
      console.log(error);
    }
  },
);

export const deleteUserProfile = createAsyncThunk(
  'userProfile/deleteUserProfile',
  async ({ id, openNotificationWithIcon }) => {
    let token = localStorage.getItem('hr-auth-token');
    try {
      const response = await axios.delete(`${baseUrl}/user/${id}/profile`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      openNotificationWithIcon('success', response.data.message);
      return id;
    } catch (error) {
      openNotificationWithIcon('error');
      console.log(error);
    }
  },
);

export const changeLogo = createAsyncThunk(
  'userProfile/changeLogo',
  async ({ formData, openNotificationWithIcon }) => {
    let token = localStorage.getItem('hr-auth-token');
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${baseUrl}/employer/profile/logo`,
        data: formData,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      openNotificationWithIcon('success', response.data.message);
      return response.data?.result;
    } catch (error) {
      openNotificationWithIcon('error');
      console.log(error);
    }
  },
);

export const removeLogo = createAsyncThunk(
  'userProfile/removeLogo',
  async ({ openNotificationWithIcon }) => {
    let token = localStorage.getItem('hr-auth-token');
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${baseUrl}/employer/profile/logo`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      openNotificationWithIcon('success', response.data.message);
    } catch (error) {
      openNotificationWithIcon('error');
      console.log(error);
    }
  },
);

export const UserProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialState,
  reducers: {
    logOut: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.token = null;
      localStorage.clear();
    },
  },
  extraReducers(builder) {
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.result;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.result;
    });
    builder.addCase(getUserProfile.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(listAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload?.result;
    });
    builder.addCase(listAllUsers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changeUserStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeUserStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.users = state.users.map((item) => {
        if (item.user_id === action.payload.userId) {
          return {
            ...item,
            account: {
              ...item.account,
              meta: {
                ...item.account.meta,
                status: action.payload.statusParam === 'activate' ? 'Active' : 'Inactive',
              },
            },
          };
        }
        return item;
      });
    });
    builder.addCase(changeUserStatus.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addNewUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addNewUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNewUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.users = [...state.users, action.payload?.result];
      }
    });
    builder.addCase(deleteUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.users = state.users.filter((item) => item.user_id !== action.payload);
    });
    builder.addCase(deleteUserProfile.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changeLogo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeLogo.fulfilled, (state, action) => {
      state.loading = false;
      state.data.documents = action.payload;
    });
    builder.addCase(changeLogo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(removeLogo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeLogo.fulfilled, (state) => {
      state.loading = false;
      state.data.documents.logo = {};
    });
    builder.addCase(removeLogo.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default UserProfileSlice.reducer;
